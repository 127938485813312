<template><div><h1 id="normal-verified-boot-mode" tabindex="-1"><a class="header-anchor" href="#normal-verified-boot-mode"><span>Normal/Verified Boot Mode</span></a></h1>
<p>In Normal/Verified Boot Mode, the read-only (RO) part of the firmware verifies the read-write (RW, aka updateable) part of the firmware, then executes it. The RW firmware verifies all other firmware is up-to-date (EC, power delivery, touchpad, touchscreen, storage, etc), verifies the (active) ChromeOS kernel on the internal storage, then boots the OS.</p>
<ul>
<li>Can only boot Google-signed ChromeOS images</li>
<li>Full verification of firmware and OS kernel</li>
<li>No root (sudo) access to the system, no ability to boot Linux natively (aka bare metal)</li>
<li>Automatically boots to Recovery Mode if any step of Verified Boot fails</li>
<li>This is the default / out-of-the-box configuration for all ChromeOS devices</li>
</ul>
</div></template>


